@import "../../styles/typo.scss";

.tip {
  background-color: rgba(255,255,255,.9);
  border: 1px solid var(--color-blue-2);

  padding: 2.4rem;
  border-radius: 1.6rem;

  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  &__head {
    display: flex;
    align-items: center;
    gap: .4rem;
    color: var(--color-blue-dark);

    span {
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }

  &__content {
    font-size: 1.6rem;
    line-height: 2.4rem;

    color: var(--color-blue-dark);
  }
}
