.c-features {
  border-top: 1px solid rgba(179, 179, 179, .5);
  padding: 5rem 0 0 0;

  &__title {

  }

  &__list {

  }

  &__item {
    border-bottom: 1px solid rgba(179, 179, 179, .5);
  }
}

.c-feature {
  padding: 3rem 0;
  text-align: center;

  &__icon {
    display: inline-block;
    width: 4.8rem;
    height: 4.8rem;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}