.c-image {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid rgba(179, 179, 179, 0.5);
  padding: 4rem 1.6rem;

  &__title {
    text-align: center;
    margin: 0 0 2rem 0;
    color: var(--color-black);
    text-transform: uppercase;
  }

  &__source {
    padding: 0 2rem;
    max-width: 34rem;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__description {
    padding: 3rem 0 0 0;
    text-align: center;
    color: var(--color-black);
  }
}
