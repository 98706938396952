@import "../../../../styles/typo.scss";

.c-story {
  padding: 3rem 0;

  &__photo {
    position: relative;
    margin: 3rem auto;
    max-width: 34rem;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__author {
    position: absolute;
    left: 2rem;
    bottom: 2rem;
    background-color: var(--color-white);
    padding: 1rem;
    border-radius: 1rem;

    &Name {
      @extend .h6;
      margin: 0 0 .3rem 0;
    }

    &Position {
      @extend .h8;
    }
  }
}