@import "../../../../styles/typo.scss";

.c-product {
  margin: 2rem 0;
  text-align: center;

  &__image {
    padding: 0 1rem;
    margin-bottom: 1rem;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__text {
    @extend .h6;
    padding: 0 3rem;
    color: var(--color-black);
    margin: 0 0 2rem 0;
  }

  &__button {

  }
}