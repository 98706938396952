@import "../../../../styles/typo.scss";

.c-switch-5 {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  &__title {
    @extend .h7;
    text-align: center;
    color: var(--color-black);
  }

  &__slider {
    text-align: center;

    &Wrapper {
      position: relative;
      display: inline-block;
      width: 33.4rem;
      z-index: 3;
    }

    &Path {
      padding: 2rem;
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    &Button {
      position: absolute;
      cursor: pointer;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        transition: opacity .2s;
      }

      &::after {
        opacity: 0;
      }

      &.-active {
        &::before {
          opacity: 0;
        }

        &::after {
          opacity: 1;
        }
      }

      &.-first {
        bottom: 4rem;
        left: 0;
        width: 9.6rem;
        height: 9.4rem;

        &::before {
          background-image: url('/assets/images/switch_5_button_1@3x.png');
        }

        &::after {
          background-image: url('/assets/images/switch_5_button_1_active@3x.png');
        }
      }

      &.-second {
        top: 3.8rem;
        left: 2.5rem;
        width: 9.7rem;
        height: 9.7rem;

        &::before {
          background-image: url('/assets/images/switch_5_button_2@3x.png');
        }

        &::after {
          background-image: url('/assets/images/switch_5_button_2_active@3x.png');
        }
      }

      &.-third {
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        width: 9.4rem;
        height: 9.6rem;

        &::before {
          background-image: url('/assets/images/switch_5_button_3@3x.png');
        }

        &::after {
          background-image: url('/assets/images/switch_5_button_3_active@3x.png');
        }
      }

      &.-fourth {
        top: 3.8rem;
        right: 2.5rem;
        width: 9.7rem;
        height: 9.7rem;

        &::before {
          background-image: url('/assets/images/switch_5_button_4@3x.png');
        }

        &::after {
          background-image: url('/assets/images/switch_5_button_4_active@3x.png');
        }
      }

      &.-fifth {
        right: 0;
        bottom: 4rem;
        width: 9.6rem;
        height: 9.4rem;

        &::before {
          background-image: url('/assets/images/switch_5_button_5@3x.png');
        }

        &::after {
          background-image: url('/assets/images/switch_5_button_5_active@3x.png');
        }
      }
    }
  }

  &__preview {
    margin: -14rem 0 0 0;
    padding: 0 0 1.6rem 0;

    &Icon {
      position: relative;
      display: flex;
      justify-content: center;
      height: 8rem;
      margin: 0 0 1rem 0;

      img {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        display: block;
        width: 8rem;
        height: 8rem;
        object-fit: contain;
        transition: opacity .2s;
        opacity: 0;
        z-index: 1;

        &.-active {
          opacity: 1;
          z-index: 2;
        }
      }
    }

    &Title {
      display: inline-block;
      max-width: 15rem;
      @extend .h6;
      font-weight: 400;
      color: var(--color-black);
    }
  }
}