@import "../../styles/typo.scss";

.c-article {
  $self: &;
  position: relative;
  border-radius: 1.2rem;
  background-color: var(--color-white);
  overflow: hidden;
  transition: height .4s;
  box-shadow: 0 1px 3px var(--color-shadow);

  &.-expanded {
    #{$self}__content {
      display: block;
    }

    #{$self}__arrow {
      transform: rotate(180deg);
    }
  }

  &__head {
    position: relative;
    display: flex;
    cursor: pointer;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    width: 7.2rem;

    img {
      display: block;
      width: 4.8rem;
      height: auto;
    }
  }

  &__info {
    flex: 1;
    min-height: 7.2rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: .4rem;

    padding: 1.2rem 0;

    span {
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 2rem;
      color: var(--color-black);
    }

    p {
      @extend .text-3;
      margin: 0;
    }
  }

  &__arrow {
    display: flex;
    align-items: center;
    padding: 0 1.6rem;

    svg {
      display: block;
    }
  }

  &__content {
    display: none;

    border-top: 1px solid #E8E8E8;
    padding: 2.4rem 1.6rem;
  }

}
