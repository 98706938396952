@import "../../styles/typo.scss";

.c-home {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  &__content {
    text-align: center;
  }

  &__logo {
    display: inline-block;
    margin: 0 0 1rem 0;

    img {
      display: block;
      width: 6.4rem;
      height: 6.4rem;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 2.4rem;
    color: var(--color-black);
  }
}