@import "../../../../styles/typo.scss";

.c-textLinks {
  margin: 2rem 0;
  border-top: 1px solid rgba(179, 179, 179, .5);

  &__item {
    border-bottom: 1px solid rgba(179, 179, 179, .5);
  }
}

.c-textLink {
  padding: 2.5rem 0;
  text-align: center;

  &__title {
    @extend .h7;
    margin:  0 0 .5rem 0;
  }

  &__link {
    
  }
}