.c-carousel {
  margin: 3rem -2rem;
  overflow-x: scroll;

  &__items {
    display: flex;
    padding: 2rem 1rem;
  }

  &__item {
    flex-shrink: 0;
    padding: 0 1rem;

    &:nth-child(odd) {
      transform: translateY(-2rem);
    }
    &:nth-child(even) {
      transform: translateY(2rem);
    }
  }

  &__image {
    width: 11rem;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}