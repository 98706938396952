@import "../../../../styles/typo.scss";

.list {
  background-color: #D8F2F3;
  padding: 2.4rem;
  border-radius: 1.6rem;

  display: flex;
  flex-direction: column;
  gap: 2rem;

  &__title {
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  &__item {
    display: flex;

    &Number {
      font-weight: 800;
      font-size: 3.2rem;
      line-height: 1;

      min-width: 5.2rem;
      color: #177285;
    }

  }
}
