:root {
  --color-default: #0A0A0A;
  --color-black: #0E0E0E;
  --color-gray: #b3b3b3;
  --color-white: #ffffff;
  --color-blue-1: #78D0E2;
  --color-blue-2: #40AAC0;
  --color-shadow: rgba(0, 121, 146, .7);
  --color-link: #1B9AB4;

  --color-green: #6EBE46;
  --color-yellow: #E6BF74;
  --color-red: #D46960;
  --color-blue: #1B9AB4;
  --color-blue-dark: #107084;
}
