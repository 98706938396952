@import "../../../../styles/typo.scss";

.c-quote {
  margin: 2rem 0;
  text-align: center;

  &__title {
    @extend .h6;
    color: var(--color-black);
  }

  &__description {
    @extend .h8;
    color: var(--color-black);
  }

  &__icon {
    padding: 2rem 0;
    color: var(--color-blue);
  }

  &__text {
    padding: 0 2rem;
    font-weight: 600;
    color: var(--color-black);
  }
}