@import "./vendor/normalize.css";
@import "./variables";
@import "./typo";

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

input, input:before, input:after {
  user-select: initial;
 }

html {
  height: 100%;
  font-size: 62.5%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 1.7rem;
  line-height: 2.4rem;
  color: var(--color-default);
  height: 100%;
  overflow-x: hidden;
}

#root {
  height: 100%;
}

a {
  text-decoration: none;
}

.c-page {
  min-height: 100%;
  background-image: linear-gradient(var(--color-blue-1), var(--color-blue-2));

  display: flex;
  flex-direction: column;

  &--white {
    background-image: none;
    background-color: var(--color-white);
  }

  &__content {
    padding: 4rem 1.6rem;
    background-color: var(--color-white);
    border-bottom-left-radius: 2.4rem;
    border-bottom-right-radius: 2.4rem;
    box-shadow: 0 1px 5px var(--color-shadow);
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    padding: 4rem 1.6rem 4rem 1.6rem;
  }

  &__remember {
    margin: 3rem 0 6rem 0;
  }

  &__articles {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }
}

.c-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  h1, h2, h3, h4, h5, h6, .h7, .h8 {
    color: var(--color-black);
  }

  &__section {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    & > * {
      margin: 0;
    }

    & + .c-image {
      border-top: 0;
      padding-top: 0;
    }
  }

  &__hr {
    color: var(--color-gray);
    opacity: .5;
    margin: 2.5rem 0;
  }

  &__title {
    text-align: center;
  }

  &__paragraph {
    margin: 2rem 0;

    a {
      color: var(--color-link);
    }
  }

  &__image {
    margin: 3rem 0;
    padding: 0 4rem;
    text-align: center;

    &.-circle {
      img {
        border-radius: 100%;
        overflow: hidden;
      }
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__definition {
    padding: 2rem 0;
    border-top: 1px solid rgba(179, 179, 179, .5);

    h6 {
      margin: 2rem 0 1rem 0;
    }
  }

}

.c-references + .c-image {
  padding: 5rem 0 0 0;
  border-top: 1px solid rgba(179, 179, 179, .5);
}

.c-button {
  border: 0;
  outline: none;
  display: inline-block;
  width: 100%;
  padding: 1.8rem 2rem;
  background: #1B9AB4;
  box-shadow: 0 4px 10px 0 rgba(27,154,180,0.50);
  border-radius: 1.5rem;

  font-weight: 700;
  font-size: 17px;
  color: #FFFFFF;
  text-transform: uppercase;
  letter-spacing: -0.41px;
  text-align: center;
  line-height: 24px;
  text-shadow: 0 1px 3px rgba(0,136,164,0.70);
}

.c-title {
  margin: 0 0 2rem 0;
  text-align: center;
}

.c-list + .c-message {
  border-top: 0;
}

.c-link {
  color: var(--color-link);
  text-decoration: none;
}

.c-profiles {
  margin: 2rem 0;
  padding: 0 0 2rem 0;
  border-bottom: 1px solid rgba(179, 179, 179, .5);

  &__item {
    &:not(:last-child) {
      margin-bottom: 4rem;
    }
  }
}

.c-profile {
  text-align: center;

  &__photo {
    display: inline-block;
    width: 15rem;
    height: 15rem;
    margin: 0 0 2rem 0;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__name {
    @extend h6;
    margin: 0 0 .5rem 0;
  }

  &__position {
    @extend .h8;
    margin: 0 0 .5rem 0;
    padding: 0 2rem;
  }

  &__link {

  }
}

.articles {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;

  &__label {
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.4rem;
    color: var(--color-white);
    text-shadow: 0 1px 0px var(--color-shadow);
  }
}
