.c-text {
  &__value {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;

    & > * {
      margin: 0;
    }
  }
}
