@import "../../../../styles/typo.scss";

.c-message {
  border-top: 1px solid rgba(179, 179, 179, .5);
  margin: 2rem 0;
  padding: 3rem 0 0 0;
  text-align: center;

  &__icon {
    margin-bottom: 1rem;

    svg {
      color: var(--color-blue);
    }
  }

  &__text {
    @extend .h6;
    color: var(--color-black);
  }
}