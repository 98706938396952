.c-contact {
  &__photo {
    display: flex;
    justify-content: center;
    margin: 0 0 2rem 0;

    img {
      display: block;
      width: 15rem;
      height: 15rem;
    }
  }
  &__heading {
    padding: 0 2rem;
    margin: 0 0 2rem 0;
    text-align: center;
  }

  &__form {
    display: none;
    position: relative;
    margin: 0 0 6rem 0;

    &.-active {
      display: block;
    }

    &.-loading {
      &::before {
        content: '';
        position: absolute;
        top: -2rem;
        left: -2rem;
        right: -2rem;
        bottom: -2rem;
        background-color: var(--color-white);
        opacity: .5;
        z-index: 1;
      }
    }
  }

  &__success {
    display: none;
    margin: 0 0 6rem 0;

    &.-active {
      display: block;
    }

    &Message {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 4rem;
      min-height: 18rem;
      text-align: center;

      img {
        display: block;
        width: 4.8rem;
        height: 4.8rem;
        margin: 0 0 1rem 0;
      }

      span {
        position: relative;
        font-weight: 600;
        font-size: 1.1rem;
        line-height: 1.4;
        text-transform: uppercase;
        color: var(--color-black);
      }
    }
  }

  &__field {
    margin: 0 0 2rem 0;
  }

  &__input {
    border: 0;
    border-radius: 0;
    width: 100%;
    display: block;
    padding: 1rem 0;
    border-bottom: 1px solid var(--color-gray);
    outline: none;
    box-shadow: none;
  }

  &__control {
    padding: 2rem 0 0 0;
  }
}