@import "../../../../styles/typo.scss";

.c-references {
  padding: 2rem 0 0 0;

  &__title {
    @extend .h7;
    text-align: center;
    color: var(--color-black);
    margin: 0 0 3rem 0;
  }

  &__description {
    @extend .text-3;
    margin: 0 0 2rem 0;
    color: var(--color-black);
    text-align: center;
  }

  &__items {

  }

  &__item {

  }
}

.-green {
  .c-reference__hr {
    svg {
      color: var(--color-green);
    }
  }
}
.-yellow {
  .c-reference__hr {
    svg {
      color: var(--color-yellow);
    }
  }
}
.-red {
  .c-reference__hr {
    svg {
      color: var(--color-red);
    }
  }
}

.c-reference {
  &__hr {
    position: relative;
    text-align: center;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 1rem;
      height: 1px;
      width: 10px;
      background-color: rgba(179, 179, 179, .5);
    }

    &::before {
      left: 0;
      width: calc(50% - 2rem);
    }

    &::after {
      right: 0;
      width: calc(50% - 2rem);
    }

    svg {
      color: var(--color-blue);
    }
  }

  &__title {
    padding: 2rem 0 0 0;
  }

  &__link {
    padding: 0 0 2rem 0;

    a {
      color: var(--color-link);
      word-break: break-word;
    }
  }
}
