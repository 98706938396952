@import "../../../../styles/typo.scss";

.c-video {
  width: 100%;

  &__title {
    @extend .text-3;
    text-align: center;
    color: var(--color-black);
    margin: 0 0 1rem 0;
  }

  &__content {

  }

  &__wrapper {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
