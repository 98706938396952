.c-definition {
  width: 100%;
  padding: 0 0 2.4rem 0;
  border-bottom: 1px solid rgba(179, 179, 179, .5);

  &__title {
    margin: 0 0 1rem 0;
    color: var(--color-black);
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    & > * {
      margin: 0;
    }
  }

  & + .c-image {
    border-top: 0;
    padding-top: 2.4rem;
  }
}
