@import "../../../../styles/typo.scss";

.c-switch-3 {
  &__title {
    @extend .h7;
    text-align: center;
    color: var(--color-black);
    margin: 4rem 0 3rem 0;
  }

  &__slider {
    text-align: center;
    border-bottom: 1px solid rgba(179, 179, 179, .5);

    &Wrapper {
      position: relative;
      display: inline-block;
      width: 33.4rem;
    }

    &Path {
      padding: 2rem;
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    &Button {
      position: absolute;
      cursor: pointer;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        transition: opacity .2s;
      }

      &::after {
        opacity: 0;
      }

      &.-active {
        &::before {
          opacity: 0;
        }

        &::after {
          opacity: 1;
        }
      }

      &.-first {
        top: 4rem;
        left: 0;
        width: 12rem;
        height: 11.3rem;

        &::before {
          background-image: url('/assets/images/switch_3_button_1@3x.png');
        }

        &::after {
          background-image: url('/assets/images/switch_3_button_1_active@3x.png');
        }
      }

      &.-second {
        left: 50%;
        top: -1rem;
        transform: translateX(-50%);
        width: 11.6rem;
        height: 11.8rem;

        &::before {
          background-image: url('/assets/images/switch_3_button_2@3x.png');
        }

        &::after {
          background-image: url('/assets/images/switch_3_button_2_active@3x.png');
        }
      }

      &.-third {
        right: 0;
        top: 4rem;
        width: 12rem;
        height: 11.3rem;

        &::before {
          background-image: url('/assets/images/switch_3_button_3@3x.png');
        }

        &::after {
          background-image: url('/assets/images/switch_3_button_3_active@3x.png');
        }
      }
    }
  }

  &__preview {
    margin: -8rem 0 0 0;
    padding: 0 0 4rem 0;

    &Icon {
      position: relative;
      display: flex;
      justify-content: center;
      height: 8rem;
      margin: 0 0 1rem 0;

      img {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        display: block;
        width: 8rem;
        height: 8rem;
        object-fit: contain;
        transition: opacity .2s;
        opacity: 0;
        z-index: 1;

        &.-active {
          opacity: 1;
          z-index: 2;
        }
      }
    }

    &Title {
      @extend .h6;
      font-weight: 400;
      color: var(--color-black);
    }
  }
}