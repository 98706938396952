h1,
h2,
h3,
h4,
h5,
h6,
.h7,
.h8 {
  margin: 0;
}

h1, .h1 {
  font-weight: 700;
  font-size: 3.4rem;
  letter-spacing: 1px;
  line-height: 4rem;
  color: var(--color-black);
}

h2, .h2 {
  font-size: 3.4rem;
  letter-spacing: 0.35px;
  line-height: 4rem;
  color: var(--color-black);
}

h3, .h3 {

}

h4, .h4 {
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 3rem;
  letter-spacing: -0.58px;
}

h5, .h5 {

}

h6, .h6 {
  font-weight: 700;
  font-size: 1.7rem;
  line-height: 2.4rem;
  letter-spacing: -0.41px;
}

.h7 {
  font-weight: 400;
  font-size: 1.7rem;
  line-height: 2.4rem;
  letter-spacing: -0.41px;
  text-transform: uppercase;
}

.h8 {
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.5rem;
  letter-spacing: 0.06px;
  text-transform: uppercase;
}

.text-3 {
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.5rem;
  letter-spacing: 0.06px;
}