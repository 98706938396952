@import "../../../../styles/typo.scss";

.c-audio {
  width: 100%;

  &__title {
    font-weight: 600;
    color: var(--color-black);
    margin: 0 0 1rem 0;
  }

  &__content {
    width: 100%;

    audio {
      width: 100%;
    }
  }
}
