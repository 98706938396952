@import "../../styles/typo.scss";

.c-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  &__logo {
    animation: pulse 1s infinite;

    img {
      display: block;
      width: 6.4rem;
      height: 6.4rem;
      transform-origin :100% 100%;
      animation: pulseOpacity 1s infinite;
    }
  }
}

@keyframes pulse {
	10% {transform: scale(1.1)}
}

@keyframes pulseOpacity {
  10% {opacity: .6;}
}