@import "../../styles/typo.scss";

.c-legal {
  display: flex;
  flex-direction: column;
  gap: .8rem;

  color: var(--color-white);
  text-shadow: 0 1px 3px var(--color-shadow);


  &__heading {
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 1.6rem;
  }

  &__content {
      font-size: 1.1rem;
    line-height: 1.4rem;
  }
}
